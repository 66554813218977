@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.tenthScreenContainer {
  width: 100%;
  height: size-rem(990px);
  position: relative;
  background-color: $clr-black;
  color: $clr-white;
  @include vp--740 {
    height: size-rem(640px, true);
    overflow-x: hidden;
  }
}

.tenthScreenWrapper {
  overflow: visible;
  height: 100%;
  width: 100%;
  padding-left: size-rem(80px);
  padding-right: size-rem(80px);
  @include flex-row_sb;
  @include vp--740 {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.tenthScreenFormBox {
  width: 50%;
  @include vp--740 {
    width: 100%;
  }
}

.tenthScreenImgBox {
  width: 40%;
  z-index: 20;
}

.tenthScreenImg {
  width: size-rem(576px);
  height: size-rem(693px);
  position: relative;
  top: size-rem(140px);
}

.tenthScreenSvg {
  position: absolute;
  width: size-rem(1037px);
  height: size-rem(1478px);
  left: size-rem(726px);
  top: size-rem(21px);
  z-index: 10;
  @include vp--740 {
    width: size-rem(308px, true);
    height: size-rem(190px, true);
    left: size-rem(426px);
    top: size-rem(0px);
  }
}
